
body {
  background: #f3f1f2;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

select, label {
  cursor: pointer;
}
.header{
  color:#F1F1F1;
  background-color: #161518;
  background-image: linear-gradient(to bottom right, #363739, #161518); 
  margin-left: 0px;
  margin-right: 0px;
}

.header_content {
  margin: 0 auto;
}
.textoblanco {
  color:#F1F1F1;
}

.content{
  background-color: #F6FBFF
}

.posnavbar {
  float: right;
  padding-top: 30px;
}
div.content {
  margin: 0 auto;
}

input{
  min-height:50px !important
}

.newCourse-Select {
  height: 50px !important;
}

html, .main_container{
  background: #f6fbff;
}

.btn-gradient-style, .boton-guardar, .boton-seleccionar{
  background-image: linear-gradient(#B0E94F, #459521) !important;
  border:0px;
  border-radius: 7px;
  height:50px;
}

.boton-add2, .boton-add, .guardarborrador{
  background-image: linear-gradient(#B5B7B8, #7E7E7F) !important;
  border:0px;
  border-radius: 7px;
  height:50px;
}

.header_content, div.content{
  max-width: 1440px;
}  

.up_content {
  margin-top: -180px;
}
.rounded_cont{
  border-radius: 16px;
  background: #FFF;
  margin-bottom: 20px;
}
.rounded_cont.rounded-select{
  padding: 0;
}
.rounded_cont.rounded-select .select__control, select.form-control {
  border-radius: 16px !important;
  outline: none !important;
  padding-left: 12px;
}

.rounded_cont.img {
  max-height: 250px;
  overflow: hidden;
  padding: 0;
}
.rounded_cont_frame {
  overflow: hidden;
  border-radius: 10px;
}
.rounded_cont_frame input[type="file"] {
  font-size: 12px;
  margin: 8px 0px;
}
.rounded_cont img{
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: none;
  border-radius: 5px;
}
.rounded_cont .t2 {
  border: 0;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  padding: 5px 14px;
  line-height: 50px;
  width: 100%;
  text-align: left;
}
.rounded_cont .ql-toolbar.ql-snow, .ql-container.ql-snow{
  border: 0 !important;
}

.rounded_cont.literal {
  background: #fdffd7;
  border-radius: 6px;
  padding: 20px 14px;
  font-style: italic;
  font-size: 12px;
}

.ojoOculto {
  float: right;
}
.card .author {
  background: #f7f5f7;
  border-radius: 6px;
  padding: 12px 14px;
}
.card .author .img {
  border-radius: 30px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 14px;
  margin-top: 14px;
}

.card .author img {
  width: 100%;
  border: 40px;
}

.card .author span {
  font-weight: 600;
  font-size: 1em;
  color: #a2a2a2;
}

.card .author .comment {
  font-size: 0.9em;
  font-weight: 600;
  padding-bottom: 8px;
}

.smooth-dnd-container {
  width: 100%;
}

.smooth-dnd-container span {
  display: block;
  width: 100%;
}

.comment_date {
  font-size: 0.75em;
  text-align: right;
  margin-top: 20px;
  position: relative;
  height: 22px;
}
.comment_date hr {
  background: #ccc;
  height: 2px;
  position: absolute;
  width: 100%;
  margin: 10px 0px;
}

.comment_date span {
  position: absolute;
  float: right;
  right: 0;
  top: 50%;
  margin-top: -8px;
  background: #fff;
  padding-left: 14px;
}

.published {
  font-size:1.1em;
  font-weight: bolder;
}
.hide {
  float: right;
  cursor: pointer;
  color: #fcdfa7;
  line-height: 37px;
}
.hide span {
  float: left;
  display: block;
  width: auto;
  line-height: 18px;
  font-size: 12px;
}
.hide svg {
  float: left;
  height: 18px;
}
.hide i {
  margin-top:5px;
}
.badge img {
  height:23px;
  width:23px;
}

.bg-orange {
  background-color: orange;
  padding: 0px 7px 0px 13px;
  line-height: 25px;
  border-radius: 20px;
  cursor: pointer;
  right: 0px;
}

.bg-orange:hover {
  background-color: darkorange;
}


.card.subtitle {
  padding-top: 20px !important;
  border-radius: 6px;
  padding: 14px 30px 6px !important;
}
.card.subtitle h3 {
  font-weight: 600;
  font-size: 20px;
  color: #c8c8c8;
}
th.actions {
  text-align: center;
}

.form-control {
  border: 0;
  font-size: 13px;
  font-family: "Montserrat";
  color: #737373;
  font-weight: 600;
  border:1px solid gainsboro;
  /* box-shadow: 0 0 30px 0 #999696; */
  border-radius: 15px;
 
}
.form-control.shadow {
  border: 1px solid #dedede;
}

.ql-container{
    max-height: 300px !important;
    height: 300px !important;
}
.ql-editor {
    font-weight: 600;
    font-family: "Montserrat";
    letter-spacing: 0.7px;
}
.beauty-checkbox input{
  display: none;
}

.content_type_container{
  margin-bottom: 20px;
}

.content_type_container .col {
  position: relative;
  overflow: hidden;
  background: #FFF;
  height: 90px;
  width: calc(100% - 10px);
  margin: 0px 15px;
  border-radius: 16px;
  cursor: pointer;
}
.content_type {
  border-radius: 16px;
  color: #f7d308;
  font-size: 17px;
  font-weight: bolder;
  position: absolute;
  bottom: 0 !important;
  text-align: center;
  padding-bottom: 8px;
  width: calc(100% - 30px);
}


.content_type_container .active, .content_type_container .active div{
  filter: none !important;
}

.content_type_container div{
  filter: grayscale(100%) !important;
}

.content_type_container .texto{
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% 0px;
}
.content_type_container .audio {
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% -92px;
}
.content_type_container .video {
  background: #FFF url(/img/ico/sprite_file_type.png) no-repeat 50% -192px;
}



#trigger {
  display: none;
}
.checker {
  background-image: url(/img/ico/checkboxes.png);
  background-position: left center;
  background-size: auto 100%;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
}
#trigger:checked + .checker {
  background-position: right center;
}


button.btn.btn-secondary, button.btn.btn-primary {
  font-size: 13px;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
  margin-right: 0;
  margin-right: 0;
  padding: 8px;
}
button.btn.btn-success.borrador {
  background: #ccc;
  border-color: #ccc;
  color: #000;
  
}
.btn_save {
  margin-top: 20px;
}

.no-gutters {
  max-width: 50%;
  flex: 50%;
}
.cBXKgf, .dJewwL{
  background: none !important;
}

.shadow{
-moz-box-shadow: 0px 0px 20px #0000001a;
-webkit-box-shadow: 0px 0px 20px #0000001a;
box-shadow: 0px 0px 20px #0000001a;

}

.rounded_cont.contents{

    margin-bottom: 10px;
    padding: 15px;
    font-size: 12px;
}

.rounded_cont.contents .actions button, .rounded_cont.contents .actions a {
  float: right;
  margin: 0;
  margin-left: 6px;
  padding: 5px 10px;
  font-size: 13px;
}
.rounded_cont.contents .actions a {
  padding: 0;
}
.contents .entry {
  font-weight: 600;
  line-height: 20px;
}

.contents .excerpt {
  font-style: italic;
}

.contents .published_date{
  font-weight: 600;
  font-style: italic;
}

tr:nth-child(odd) {
  background-color:#f2f2f2;
}
tr:nth-child(even) {
  background-color:#fbfbfb;
}
thead tr th {
  background-color:#fbfbfb;
  color: #CCCCCC;
}

td.showComment {
  cursor: pointer;
  text-align: center;
}
th.showComment {
  text-align: center;
}
td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
th.delete {
  text-align: center;
}
td.showComment img {
  height:23px;
  width:23px;
}
td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
.tablaComentarios tbody tr td.entry{
  font-weight: lighter;
}
.tablaComentarios tbody tr td.comments{
  font-weight: bolder;
}
.tablaComentarios {
  vertical-align: middle;
}

.rounded_cont.contents.shadow{
  padding:0px;
  margin:0px 0px 20px 0px;
}
.table-light.table{
  margin-bottom:0px;
}

.edit {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}
.edit a:hover {
  color: black;
}
.visits {
  text-align: center;
}
table .comments {
  text-align: center;
}
.published_date {
  text-align: center;
}
.published_date span{
  /*font-weight: lighter;*/
  font-style: normal;
}
.excerpt span{
  /*font-weight: lighter;*/
  font-style: normal;
}

.rotate {
  transform: rotate (180deg);
}

tr:nth-child(odd) {
  background-color:#f2f2f2;
}
tr:nth-child(even) {
  background-color:#fbfbfb;
}
thead tr th {
  background-color:#fbfbfb;
  color: #CCCCCC;
}
.user{
  font-weight: bolder;
  font-size: 1rem;
}
.showComment {
  cursor: pointer;
  text-align: center;
}
.showComment {
  text-align: center;
}
.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}

.delete {
  text-align: center;
}

td.showComment img {
  height:23px;
  width:23px;
}

td.delete {
  cursor: pointer;
  text-align: center;
  font-size:1.5em;
}

.tablaUsuarios tbody tr td.entry{
  font-weight: lighter;
}

.tablaUsuarios tbody tr td.comments{
  font-weight: bolder;
}

.tablaUsuarios {
  vertical-align: middle;
}

.table thead th {
  font-size: 0.9em;
}

.main_content h3 {
  color:rgb(121, 121, 121);
  font-weight:bold;
}

.main_content h4 {
  color: #7d7d7d;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  margin: 0 0 10px 0;
}

.table-light.table{
  margin-bottom:0px;
}

td.avatar img{
  width:60px;
  border-radius:70px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
}

.row div a.btn.newUser{
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
  color: #FFF;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}

.row div a.btn.altas{
  background: #d8d8d8;
  color: #8b8b8b;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}



.modal-registry label {
  font-size: 0.7rem;
  font-weight: bolder;
}

.modal-registry input {
  font-size: 0.7rem;
  font-weight: bolder;    
  border-radius: 5px;
  height:38px;
}

.modal-registry select {
  font-size: 0.7rem;
  font-weight: bolder;    
  border-radius: 5px;
}

.modal-registry [id^="ad_"] {
  height: 18px;
  margin-right: 8px;
  display: block;
  float: left;
}

.modal-registry [for^="ad_"] {
  margin: 0 5px 0px 0;
  display: block;
  float: left;
}

.modal-registry h3 {
  font-size: 12px;
}

.modal-registry .area_admin, .modal-registry .area_apps{
  border: 1px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;
}
.modal-registry .area_admin .area{
  width: calc(100% / 3);
  float: left;
}

.undefinedmarcomodal {
  width:75%;
  max-width: 800px;

}

.modal-footer .btn.newUser{
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(#b1ed4d));
  color: #FFF;
  opacity: 0.8;
  padding: 5px 15px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 11px;
}   

.list_categorias .thumbnail {
  width: 12%;
}
.list_categorias .title, .list_categorias .total, .list_categorias .parent_category{
  line-height: 20px;
  padding-top: 18px;
}
.list_categorias .title, .list_categorias .total, .list_categorias .parent_category{
  font-size: 12px;
}
.list_categorias .title {
    width: 32%;
    font-size: 14px;
    font-weight: 600;
}
.list_categorias .total {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  width: 16%;

}

.list_categorias .parent_category {
  text-align: center;
  width: 20%;
  
  font-size: 14px;
  font-weight: 600;
}

.list_categorias td.edit, .list_categorias td.delete {
  width: 10%;
line-height: 50px;
}

.add-category {
  margin-top: 20px;
}

.form-category label {
  float: left;
  display: block;
  padding: 10px 33px 0px 4px;
  font-weight: 600;
}

.form-category .rounded_cont {
  padding: 10px;
}

.form-category .form-control {
  font-size: 14px;
}

.form-category .rounded_cont.img {
  padding: 10px 20px 20px 20px;
}

.form-category h2 {
  font-size: 21px;
  font-weight: 600;
  margin: 10px 0px 15px 20px;
}

.add-category span {
  display: block;
  background: #28a745;
  color: #FFF;
  overflow: hidden;
  width: auto;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin: 0 auto;
}

.cat-modal{
  display: block; 
  width: 1200px; 
  padding-left: 0px;
}

.listado .svg-inline--fa {height: 1.5em; width: auto;}

.listado .rt-th {
  line-height: 60px !important;
  font-size: 14px;
  font-weight: 600;
}

.listado .rt-th div{
  color: #9aa5a9;
}

.listado .rt-th.title div, .listado .rt-th.category div, .listado .rt-th.user div, .listado .rt-th.file div, .listado .rt-th.category div, .listado .rt-th.email div, .listado .rt-th.author div, .listado .rt-th.comment div {
  text-align: left;
  padding: 0 5px;
}

.listado .rt-tbody .rt-td {
  border-right: 0px solid rgba(0,0,0,0.02);
  display: flex;
  justify-content: center;
  align-items: center;
}

.listado .rt-td div {
  line-height: 60px;
  font-size: 14px;
  font-weight: 600;
  color: #9aa5a9;
  text-align: center;
  padding: 0px 5px;
  width: 100%;
  overflow: hidden;
}

.listado .rt-td a{ color: inherit; }

.listado .rt-td .user, .listado .rt-td .file, .listado .rt-td .category, .listado .rt-td .title, .listado .rt-td .author{
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #363a46;
}
.listado .rt-td .image, .listado .rt-td .avatar {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listado .rt-td .author {
  text-align: center;
  font-weight: 800;
  text-align: left;
}
.listado .rt-td .author i {
  font-weight: normal;
  color: #d4d4d4;
}
.listado .rt-td .comment {
  text-align: left;
  line-height: 20px;
  padding: 0px;
  height: 100%;
  white-space: normal;
}
.listado .rt-td .email{
  text-align: left;
}
.listado .rt-td .image img, .listado .rt-td .avatar img {
  max-width: none;
  width: auto;
  height: 100%;
}

.listado .rt-td .estado {
  color: #d5be18;
}

.listado .rt-td .estado .draft {
    font-style: italic;
    color: #ff9292;
}

.galeria .modal-footer {
  width: 100%;
  margin: 0;
}

.listado .rt-td svg{
  height: 16px;
}

.listado .rt-td .delete, .listado .rt-td .edit{
    color: #000;
    font-size: 15px;
    line-height: 15px;
}



.listado .rt-td .visible {
  max-width: 40px;
  cursor: pointer;
}

textarea {
  border: 0;
  background: none;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  outline: none;
  resize: none;
  cursor: none;
}

textarea.notification {
  height: 150px !important;
  color: #808080;
}

.new-content-image label.choose{
/* margin-top: 40px; */
margin-top: 10px;
cursor: pointer;
}
.new-content-image label.choose:hover{
text-decoration: underline;
-webkit-box-shadow: none;
box-shadow: none;

}
.new-content-image input {
  font-size: 12px;
  padding: 0;
}
.new-content-image .carga-imagen {
  line-height: 20px;
  height: auto;
}

.new-content-image .carga-imagen div {
  height: auto;
  padding: 1px 15px;
  margin: 4px 0px;
  line-height: 30px;
}
.new-content-image .carga-imagen div:nth-child(2) {
  margin-right: 0;
  margin-left: 7px;
}

.new-content-image .carga-imagen svg {
  
  max-height: 13px;
  width: auto;
}

.new-content-image .carga-imagen svg + label {
  margin-left: 8px;
  cursor: pointer;
}

.new-content-image .carga-imagen label {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #7d7d7d;
    margin: 0;
}

.uploadmedia_buttons_modalfooter.modal-footer {
  padding: 0 0;
  margin: 0;
  margin-bottom: 15px;
}

.uploadmedia_buttons_modalfooter.modal-footer button {
  margin: 0;
}

.new-content-image input[type="file"] {
  background: #ccc;
  display: block !important;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}

.new-content-btn {
  display: block;
}
.add-media{
  margin-top: 20px;
}

.react-datetime-picker, .react-datetime-picker__button {
  width: 100%;
  background: #FFF;
  padding: 5px;
  border: 0 !important;
}
.react-datetime-picker{
  padding: 10px;
}
.react-datetime-picker__button__input__divider {
  padding: 1px 4px !important;
}

.react-datetime-picker__button{
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  border: thin solid gray;
  width: 97.5%;
  margin: 0 auto;
}

.rounded_cont.calendar {
  overflow: inherit;
  border-radius: 16px;
  margin-top: 5px;
}

.rounded_cont.borrador {
  padding: 15px;
  line-height: 22px;
  margin: 0 0 20px 0;
}

.rounded_cont.borrador label {
  margin: 0;
  cursor: pointer;
}

.rounded_cont.borrador label em{
  font-size: 12px;
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
}

.react-datetime-picker__calendar {
  width: 100% !important;
}

.react-datetime-picker__calendar .react-calendar {
  width: 90%;
  margin: 0 auto 15px auto;
  border-radius: 4px;
  border: 1px solid black;
  background: #f3ffe7;
  padding: 3px;
}

.react-datetime-picker__calendar, .react-datetime-picker__clock {
  bottom: 100%;
  margin-bottom: 14px;
  top: inherit !important;
  padding: 15px 0px 0 0;
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.react-datetime-picker__clear-button{
  display: none;
}

.react-calendar__tile--active {
  background: black !important;
  border-radius: 3px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: black!important;
  color: #FFF;
  border-radius: 3px;
}

button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus {
  background-color: #ddf7c4;
}

.modal-body .modalChoise{
  width: 5%;
}

.modal-body .react-datetime-picker {
  width: 95%;
  padding: 0;
  margin: 10px 0 10px -7px;
}

.react-datetime-picker__button__input__input {
  padding: 0px 6px !important;
  margin: 0 0px;
  cursor: pointer;
  border-radius: 2px;
  outline: none;
  font-weight: 600 !important;
  color: #8c8c8c;
}

.react-datetime-picker__button__input__input:hover {
  background: black;
  color: #FFF;
}

.react-datetime-picker__clock {
  
  border: 0 !important;
  margin: 0 auto;
  width: 100% !important;
  background: none !important;
}
button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus {
  background-color: black;
}


.react-datetime-picker__button__icon {
  margin: 0;
}

.react-clock {
  margin: 0 auto;
}

.react-clock__face {
  background: #c7eda2;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 10px;
  float: left;
  margin-left: 14px;
  width: 100%;
  text-decoration: none;
}

.pagination li {
  width: 30px;
  height: 30px;
  background: #007bff;
  margin: 1px;
}

.pagination a {
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #FFF !important;
  display: block;
}

.pagination a:hover {
  background: #046ad6;
  text-decoration: none;
}

.list-search {
  font-size: 14px;
  padding: 10px 20px 10px 40px;
  background: url(/img/search.png) no-repeat 13px;
  background-size: 20px;
  font-weight: 600;
  font-style: italic;
}

.form-control:focus {
  box-shadow: none;
  color: #808080;
}

td.valign{
  vertical-align: middle;
}
.coverAll {
  z-index: 1000;
  background-color: #333333de;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
#subemedio {
  background: -webkit-gradient(linear, 100% 0%, 10% 21%, from(#54a525), to(black));
}

.sin-asignar {
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
  color: #807e80;
}

.sin-asignar span {
  font-weight: 600;
}

.copyURL {
  background: url(/img/copy.png) no-repeat 50% !important;
  display: block;
  width: 30px;
  height: 30px;
  background-size: 50% !important;
  background-color: #007bff !important;
  border-radius: 6px;
  position: absolute;
  left: -5px;
  z-index: 10;
  cursor: pointer;
  outline: none;
}
.copyURL.copied {
  background-color: #62886a !important;
}

.copyURL-txt {
  background: #fffffff0;
  overflow: hidden;
  width: 84%;
  display: block;
  position: absolute;
  right: 0;
  font-size: 12px;
  overflow: hidden;
  line-height: 30px;
  height: 30px;
  cursor: text;
  border-radius: 5px;
  z-index: 1;
  border: 1px solid #e0e0e0;
  margin-right: 15px;
}

.main_content .row .btns{
  padding-top: 10px;
  padding-left: 5px;
}

.main_content .row .newUser.btn {
  background: #28a745;
  color: #FFF;
  overflow: hidden;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  width: auto;
  padding: 5px 15px;
  font-size: 12px;
  margin-top: 10px;
}

.mediacont .video{
  position: relative !important;
  padding: 0 0 0 0 !important;
  width: 100%;
  height: auto !important;
}

.mediacont .video video {
  width: 100%;
  top: 0;
  border-radius: 5px;
  overflow: hidden;
  max-height: 157px;
  background: #525252;
}

.mediacont .audio audio {
  margin: 48px auto 0 auto;
  width: 96%;
}
.mediacont .video, .mediacont .audio {
  height: 180px;
  background-size: cover;
  cursor: pointer;
  background-position: 50%;
  margin: 10px 0px;
  padding: 9px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #135823;
  color: #FFF;
}

.mediacont .media-title {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  display: block;
  padding: 0;
  height: 50px;
}

.form-category input[type="file"] {
  font-size: 10px;
}

span.publish_date{
  font-size: 12px;
  color: #7d7d7d;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  display: block;
  padding-bottom: 1px;
  margin-bottom: 6px;
}
span.publish_date + time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
}


.pretty.p-switch.p-fill input:checked~.state label:after {
  left: calc(1em);
}

.pretty.p-switch .state label:after, .pretty.p-switch .state label:before {
  left: 2px;
  margin-top: -1px;
}

.pretty.p-switch .state:before {
  border: 2px solid #bdc3c7;
  height: 1em;
}


.scheduled {
  font-weight: normal;
}
.rounded_cont .destacada img {
  height: 26px;
  width: auto;
  cursor: pointer;
}

.literal {
  color: #FFF;
  font-size: 13px;
  border-radius: 16px;
  padding: 12px 16px;
  background: #5f5f5f;
  margin-bottom: 20px;
}

.image.modal-image {
  width: 100%;
  max-height: none !important;
}

.text-muted {
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 1240px) {
  .header_content, .content {
    max-width: 1240px;
  }
  
}

@media screen and (max-width: 992px) {
  .header_content, .content {
    padding: 10px;
  }
  .up_content {
    margin-top: 15px;
  }
  .logo{
    max-width: 300px;
    margin: 0 auto;
  }
}